import { useRpcClient } from '@gain/api/swr'
import { ConferenceExhibitor, RpcMethodMap } from '@gain/rpc/cms-model'
import { useDialogState } from '@gain/utils/dialog'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router'
import * as yup from 'yup'

import {
  InputFieldAutoComplete,
  InputFieldText,
  InputFormProvider,
  useInputFormAPI,
} from '../../../../common/input-fields'
import { yupUrl } from '../../../../common/input-fields/input-field-yup'
import RightDrawer, { RightDrawerRef } from '../../../../common/right-drawer'

export interface ConferenceEditionExhibitorRouteProps {
  onCrudAction?: (event: 'new' | 'update' | 'delete', item?: ConferenceExhibitor | null) => void
}

export default function ConferenceEditionExhibitorRoute({
  onCrudAction,
}: ConferenceEditionExhibitorRouteProps) {
  const [open, handleOpen, handleClose] = useDialogState()
  const routeParams = useParams<{ exhibitorId: string; id: string }>()
  const drawerRef = useRef<RightDrawerRef>(null)
  const rpcClient = useRpcClient<RpcMethodMap>()

  const inputFormAPI = useInputFormAPI({
    getMethod: 'data.getConferenceExhibitor',
    createMethod: 'data.createConferenceExhibitor',
    updateMethod: 'data.updateConferenceExhibitor',
    deleteMethod: 'data.deleteConferenceExhibitor',

    validationSchema: {
      linkedAssetId: yup.number().nullable(),
      name: yup
        .string()
        .trim()
        .when('linkedAssetId', ([linkedAssetId], schema) =>
          linkedAssetId ? schema.nullable() : schema.required()
        ),
      url: yupUrl()
        .transform((value) => value || null)
        .nullable(),
    },
  })

  const handleUpdateOrCreate = useCallback(() => {
    inputFormAPI.form.handleSubmit(async (values) => {
      if (routeParams.exhibitorId === 'new') {
        await inputFormAPI.create(
          {
            partial: {
              conferenceEditionId: parseInt(routeParams.id, 10),
              ...values,
            },
          },
          (rpcResponse) => {
            onCrudAction?.('new', rpcResponse)
            drawerRef.current?.close()
          }
        )
      } else {
        const patchSuccessful = await inputFormAPI.patch(routeParams.exhibitorId, {
          name: values.name,
          linkedAssetId: values.linkedAssetId,
          url: values.url,
        })

        if (patchSuccessful) {
          onCrudAction?.('update')
          drawerRef.current?.close()
        }
      }
    })()
  }, [inputFormAPI, onCrudAction, routeParams.exhibitorId, routeParams.id])

  const handleDeleteExhibitor = useCallback(async () => {
    handleClose()

    if (await inputFormAPI.delete(routeParams.exhibitorId)) {
      onCrudAction?.('delete')
      drawerRef.current?.close()
    }
  }, [handleClose, onCrudAction, rpcClient])

  useEffect(() => {
    if (routeParams.exhibitorId !== 'new') {
      inputFormAPI.fetch(routeParams.exhibitorId)
    }
  }, [routeParams.exhibitorId])

  return (
    <RightDrawer
      ref={drawerRef}
      action={
        <Button
          disabled={inputFormAPI.form.formState.isSubmitting}
          onClick={handleUpdateOrCreate}
          variant={'contained'}>
          Save
        </Button>
      }
      footerAction={
        routeParams.exhibitorId !== 'new' && (
          <Button
            color={'error'}
            disabled={inputFormAPI.form.formState.isSubmitting || !inputFormAPI.fetchedRecord}
            onClick={handleOpen}
            variant={'contained'}>
            Delete
          </Button>
        )
      }
      title={routeParams.exhibitorId !== 'new' ? 'Edit exhibitor' : 'New exhibitor'}>
      <InputFormProvider form={inputFormAPI.form}>
        <InputFieldAutoComplete
          label={'Asset'}
          labelProp={'name'}
          method={'data.listAssets'}
          name={'linkedAssetId'}
          valueProp={'id'}
        />

        <InputFieldText
          label={'Name'}
          name={'name'}
        />

        <InputFieldText
          label={'URL'}
          name={'url'}
        />

        {inputFormAPI.fetchedRecord && (
          <Dialog
            onClose={handleClose}
            open={open}>
            <DialogTitle>Delete exhibitor</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete <b>{inputFormAPI.fetchedRecord.name}</b>?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                color={'error'}
                onClick={handleDeleteExhibitor}
                variant={'contained'}>
                Delete exhibitor
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </InputFormProvider>
    </RightDrawer>
  )
}
