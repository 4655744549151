import { Asset, AssetListItem } from '@gain/rpc/cms-model'
import { AssetProfileType } from '@gain/rpc/shared-model'
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import StarIcon from '@mui/icons-material/Star'
import StarHalfIcon from '@mui/icons-material/StarHalf'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined'
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined'
import CircularProgress from '@mui/material/CircularProgress'
import { listItemIconClasses } from '@mui/material/ListItemIcon'
import { styled } from '@mui/material/styles'
import {
  GridActionsCellItem,
  GridActionsCellItemProps,
} from '@mui/x-data-grid/components/cell/GridActionsCellItem'
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams'
import { useSnackbar } from 'notistack'
import { ReactElement, useCallback } from 'react'
import { useHistory } from 'react-router'

import ActionsMenuItem from '../../../common/actions-menu-item'
import { useInputFormContext } from '../../../common/input-fields'
import { useSwrDataGrid } from '../../../common/swr-data-grid'
import PublishAssetSnackbar from '../detail-header/publish-asset-button/publish-asset-snackbar'
import { hasAssetEverBeenPublished } from './asset-actions-utils'

export interface AssetActionsProps extends Pick<GridRowParams<AssetListItem | Asset>, 'row'> {
  hidePublish?: boolean
}

const StyledDeleteGridActionsCellItem = styled(ActionsMenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  fill: theme.palette.error.main,

  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.error.main,
  },
}))

export default function AssetActions({
  row,
  hidePublish,
}: AssetActionsProps): ReactElement<GridActionsCellItemProps>[] {
  const inputFormContext = useInputFormContext()
  const swrDataGrid = useSwrDataGrid()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const history = useHistory()

  const handleChangeProfileType = useCallback(
    (newType: AssetProfileType) => async () => {
      await inputFormContext.patch({ profileType: newType })

      if (!swrDataGrid.api) {
        return
      }

      // Update the row
      await swrDataGrid.api.updateRow(row.id, {
        profileType: newType,
      })
    },
    [inputFormContext, row.id, swrDataGrid.api]
  )

  const handlePublish = useCallback(async () => {
    const key = 'publish-asset'

    enqueueSnackbar(undefined, {
      key,
      persist: true,
      content: () => (
        <PublishAssetSnackbar
          id={row.id}
          onClose={() => closeSnackbar(key)}
          onSubmit={async () => {
            closeSnackbar(key)

            // Publish the asset
            await inputFormContext.publish(false, async () => {
              if (!swrDataGrid.api) {
                return
              }

              // Update the row
              await swrDataGrid.api.updateRow(row.id, {
                profileLive: true,
              })
            })
          }}
        />
      ),
      preventDuplicate: true,
    })
  }, [enqueueSnackbar, row.id, closeSnackbar, inputFormContext, swrDataGrid.api])

  const handleUnpublish = useCallback(async () => {
    // Un publish the asset
    await inputFormContext.unPublish(async () => {
      if (!swrDataGrid.api) {
        return
      }

      // Update the row
      await swrDataGrid.api.updateRow(row.id, {
        profileLive: false,
      })
    })
  }, [swrDataGrid.api, inputFormContext, row.id])

  const handleDelete = useCallback(() => {
    inputFormContext.delete(() => {
      // From detail page, redirect back to assets table
      if (!swrDataGrid.api) {
        history.push('/assets')
        return
      }

      // Refresh the grid
      swrDataGrid.api.swr.mutate()
    })
  }, [swrDataGrid.api, inputFormContext, history])

  const handleArchive = useCallback(async () => {
    if (row.live) {
      await inputFormContext.archive()
    } else {
      await inputFormContext.unArchive()
    }

    if (!swrDataGrid.api) {
      return
    }

    // Refresh the grid
    await swrDataGrid.api.swr.mutate()
  }, [row.live, swrDataGrid.api, inputFormContext])

  // Show loader if we are rendering inside the grid and doing something
  if (inputFormContext.busy && swrDataGrid.api) {
    return [
      <GridActionsCellItem
        icon={<CircularProgress size={16} />}
        label={''}
      />,
    ]
  }

  return [
    ![AssetProfileType.Automated, AssetProfileType.Full].includes(row.profileType) && (
      <ActionsMenuItem
        key={'change-profile-to-full'}
        disabled={row.profileLive}
        icon={<StarIcon />}
        label={'Change profile to full'}
        onClick={handleChangeProfileType(AssetProfileType.Full)}
        showInMenu
      />
    ),

    row.profileType !== AssetProfileType.Automated &&
      row.profileType !== AssetProfileType.Limited && (
        <ActionsMenuItem
          key={'change-profile-to-limited'}
          disabled={row.profileLive}
          icon={<StarHalfIcon />}
          label={'Change profile to limited'}
          onClick={handleChangeProfileType(AssetProfileType.Limited)}
          showInMenu
        />
      ),

    row.profileType !== AssetProfileType.Automated &&
      row.profileType !== AssetProfileType.Minimal && (
        <ActionsMenuItem
          key={'change-profile-to-minimal'}
          disabled={row.profileLive}
          icon={<StarOutlineIcon />}
          label={'Change profile to minimal'}
          onClick={handleChangeProfileType(AssetProfileType.Minimal)}
          showInMenu
        />
      ),

    !hidePublish && row.profileType !== AssetProfileType.Automated && !row.profileLive && (
      <ActionsMenuItem
        key={'publish'}
        icon={<CheckCircleOutlinedIcon />}
        label={'Publish company'}
        onClick={handlePublish}
        showInMenu
      />
    ),

    !hidePublish && row.profileType !== AssetProfileType.Automated && row.profileLive && (
      <ActionsMenuItem
        key={'unpublish'}
        icon={<UnpublishedOutlinedIcon />}
        label={'Unpublish company'}
        onClick={handleUnpublish}
        showInMenu
      />
    ),

    (hasAssetEverBeenPublished(row) || row.profileInConversion) && (
      <StyledDeleteGridActionsCellItem
        key={'archive'}
        icon={row.live ? <ArchiveOutlinedIcon /> : <UnarchiveOutlinedIcon />}
        label={`${row.live ? 'Archive' : 'Unarchive'} company`}
        onClick={handleArchive}
        showInMenu
      />
    ),
    !row.live && (
      <StyledDeleteGridActionsCellItem
        key={'delete'}
        icon={<DeleteIcon />}
        label={'Delete'}
        onClick={handleDelete}
        showInMenu
      />
    ),
  ].filter(Boolean) as ReactElement<GridActionsCellItemProps>[]
}
