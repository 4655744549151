import Button from '@mui/material/Button'

import { useInputFormContext } from '../../common/input-fields'

export interface ItemPagePublishActionProps {
  live?: boolean
  onPublish?: (publish: () => Promise<void>) => void
}

export function ItemPagePublishAction({ live, onPublish }: ItemPagePublishActionProps) {
  const inputFormContext = useInputFormContext()

  if (!inputFormContext) {
    return null
  }

  return (
    <div>
      {live && (
        <Button
          color={'error'}
          onClick={() => inputFormContext.unPublish()}
          variant={'contained'}>
          Unpublish
        </Button>
      )}

      {!live && (
        <Button
          onClick={() => {
            if (onPublish) {
              onPublish(async () => await inputFormContext.publish())
            } else {
              inputFormContext.publish()
            }
          }}
          variant={'contained'}>
          Publish
        </Button>
      )}
    </div>
  )
}
