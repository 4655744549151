import { useRpcClient } from '@gain/api/swr'
import { RpcMethodMap } from '@gain/rpc/cms-model'
import { isJsonRpcError } from '@gain/rpc/utils'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import Button from '@mui/material/Button'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Paper from '@mui/material/Paper'
import { styled, useTheme } from '@mui/material/styles'
import { useSnackbar } from 'notistack'
import { ForwardedRef, forwardRef, useRef } from 'react'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import {
  InputFieldDate,
  InputFieldRadioGroup,
  InputFormProvider,
} from '../../../../common/input-fields'

interface AssetPublishModalProps {
  id: number
  onClose: () => void
  onSubmit: () => void
}

enum UpdateReason {
  Other = 'other',
  Financials = 'financials',
}

interface FormData {
  updateReason: UpdateReason
  financialsAt: Date | null
  financialsWhen: string | null
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: 352,
  borderRadius: theme.shape.borderRadius,
}))

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(),
}))

function PublishAssetSnackbar(
  { id, onSubmit, onClose }: AssetPublishModalProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const currentDateRef = useRef(new Date())
  const rpcClient = useRpcClient<RpcMethodMap>()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()

  const form = useForm<FormData>({
    defaultValues: {
      updateReason: UpdateReason.Other,
      financialsAt: null,
      financialsWhen: null,
    },
    resolver: yupResolver(
      yup.object().shape({
        updateReason: yup.string().oneOf(Object.values(UpdateReason)).required('Required'),
        financialsAt: yup
          .date()
          .nullable()
          .when('updateReason', {
            is: UpdateReason.Financials,
            then: (schema) => schema.required(),
          }),
      })
    ),
  })

  const handleSubmit = form.handleSubmit(async (formData) => {
    if (formData.updateReason === UpdateReason.Other) {
      onSubmit()
    } else if (formData.updateReason === UpdateReason.Financials) {
      try {
        await rpcClient({
          method: 'data.updateAsset',
          params: {
            id,
            partial: {
              financialsAt: formData.financialsAt,
            },
          },
        })

        onSubmit()
      } catch (error) {
        if (isJsonRpcError(error)) {
          enqueueSnackbar(error.message, {
            key: 'asset-update-error',
            preventDuplicate: true,
            variant: 'error',
          })
        }
      }
    }
  })

  const watchUpdateReason = form.watch('updateReason')
  const watchFinancialsWhen = form.watch('financialsWhen')

  useEffect(() => {
    if (watchUpdateReason === UpdateReason.Other) {
      form.setValue('financialsAt', currentDateRef.current)
    }
  }, [form, watchUpdateReason])

  return (
    <ClickAwayListener onClickAway={onClose}>
      <StyledPaper ref={ref}>
        <InputFormProvider form={form}>
          <DialogTitle>Publish options</DialogTitle>
          <StyledDialogContent>
            <InputFieldRadioGroup
              defaultValue={UpdateReason.Other}
              label={'Reason for update'}
              name={'updateReason'}
              options={[
                {
                  label: 'New financials',
                  value: UpdateReason.Financials,
                },
                {
                  label: 'Other',
                  value: UpdateReason.Other,
                },
              ]}
              required
            />

            {watchUpdateReason === UpdateReason.Financials && (
              <InputFieldRadioGroup
                defaultValue={currentDateRef.current}
                label={'When were the financials published?'}
                name={'financialsWhen'}
                options={[
                  {
                    label: 'Less than 4 weeks ago',
                    value: 'today',
                  },
                  {
                    label: 'More than 4 weeks ago',
                    value: 'specify',
                  },
                ]}
                required
              />
            )}

            {watchUpdateReason === UpdateReason.Financials && watchFinancialsWhen === 'specify' && (
              <InputFieldDate
                label={'On which date?'}
                name={'financialsAt'}
                slotProps={{
                  popper: {
                    slotProps: {
                      root: {
                        style: { zIndex: theme.zIndex.snackbar },
                      },
                    },
                  },
                }}
                disableFuture
              />
            )}
          </StyledDialogContent>
          <DialogActions>
            <Button
              disabled={!form.formState.isValid}
              onClick={handleSubmit}
              variant={'contained'}>
              Continue
            </Button>
          </DialogActions>
        </InputFormProvider>
      </StyledPaper>
    </ClickAwayListener>
  )
}

export default forwardRef(PublishAssetSnackbar)
